.hero-img {
    width: 100%;
    background: rgba(0,0,0,0.2);
    height: 60vh;
    position: relative;
}

.hero-img::before{
    content: "";
    background: url("https://images.unsplash.com/photo-1563089145-599997674d42?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80");
    background-size: cover;
    background-position: center top;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heading h1 {
    margin: 3px;
    height: auto;
    background: transparent;
    padding: 0;
    border: none;
}
  
  /* button styling */
.heading h1{
    --border-right: 6px;
    --text-stroke-color: rgba(255,255,255,0.6);
    --animation-color: #00ff00;
    --fs-size: 2em;
    letter-spacing: 3px;
    text-decoration: none;
    font-size: var(--fs-size);
    font-family: "Arial";
    position: relative;
    margin-top: 5px;
    margin-bottom: 20px;
    color: transparent;
    -webkit-text-stroke: 1px var(--text-stroke-color);
}
  /* this is the text, when you hover on button */
.hover-text {
    position: absolute;
    box-sizing: border-box;
    content: attr(data-text);
    color: var(--animation-color);
    width: 0%;
    inset: 0;
    
    border-right: var(--border-right) solid var(--animation-color);
    overflow: hidden;
    transition: 0.5s;
    -webkit-text-stroke: 1px var(--animation-color);
}
  /* hover */
.heading h1:hover .hover-text {
    width: 100%;
   
    filter: drop-shadow(0 0 23px var(--animation-color))
}

.hero-img h1 {
    font-size: 2.4rem;
}

.hero-img p {
    font-size: 1.4rem;
    text-align: center;
}

@media screen and (max-width: 640px) {
    .hero-img{
        height: 45vh;
    }
    .hero-img h1 {
        font-size: 2rem;
    }

    .hero-img p {
        font-size: 1.4rem;
        text-align: center;
    }
}