.mask{
    width: 100%;
    height: 100vh;
    position: relative;
}
.into-img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}
.mask::after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000000;
    opacity: 0.5;
    
}
.hero{
    height: 100%;
    width: 100%;
}
.hero .content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
}


@keyframes rainbow-colors {
    0% { color: hsl(300, 100%, 50%); }
    10% { color: hsl(270, 100%, 50%); }
    20% { color: hsl(240, 100%, 50%); }
    30% { color: hsl(210, 100%, 50%); }
    40% { color: hsl(180, 100%, 50%); }
    50% { color: hsl(150, 100%, 50%); }
    60% { color: hsl(120, 100%, 50%); }
    70% { color: hsl(90, 100%, 50%); }
    80% { color: hsl(60, 100%, 50%); }
    90% { color: hsl(30, 100%, 50%); }
    100% { color: hsl(0, 100%, 50%); }
}
.hero .content h1 {
    overflow: hidden;
    padding: 5px 0 9px; 
    border-right: 0.1em solid purple;
    white-space: nowrap; 
    margin: 0 auto; 
    letter-spacing: 0.15em; 
    animation: typing 2s steps(30, end) forwards,textclip 10s linear infinite;
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    background-image: linear-gradient(
    -225deg,
    #00ff00 0%,
    #ffff00 16%,
    #ff6700 33%,
    #ff3131 50%,
    #9d00ff 67%,
    #1589ff 84%,
    #00ff00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h1.typing {
    font-size: 2rem;
    color: #333;
    text-align: center;
}
@keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  
@keyframes textclip {
    to {
      background-position: 1000% center;
    }
}
 
.hero .content p{
    font-size: 1.4rem;
    font-weight: 200;
    text-align: center;
    text-transform: uppercase;
}
.content button{
    margin: 0.3rem 0.5rem;
}

@media screen {
    .hero .content h1{
        font-size: 2.3rem;
    }
    .hero .content p{
        font-size: 1.4rem;
    }
    
}