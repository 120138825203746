.switch {
    display: block;
    --width-of-switch: 2.6em;
    --height-of-switch: 1.6em;
    /* size of sliding icon -- sun and moon */
    --size-of-icon: 1.2em;
    /* it is like a inline-padding of switch */
    --slider-offset: 0.3em;
    position: relative;
    width: var(--width-of-switch);
    height: var(--height-of-switch);
    margin-top: 1rem;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f4f4f5;
    transition: .4s;
    border-radius: 30px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: var(--size-of-icon,1.4em);
    width: var(--size-of-icon,1.4em);
    border-radius: 20px;
    left: var(--slider-offset,0.3em);
    top: 50%;
    transform: translateY(-50%);
    background: linear-gradient(40deg,#ff0000,#f38601 70%);
    ;
   transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #303136;
  }
  
  input:checked + .slider:before {
    left: calc(100% - (var(--size-of-icon,1.4em) + var(--slider-offset,0.3em)));
    background: #303136;
    /* change the value of second inset in box-shadow to change the angle and direction of the moon  */
    box-shadow: inset -3px -2px 5px -2px #8983f7, inset -10px -4px 0 0 #a3dafb;
  }
  @media screen and (max-width: 640px) {
    .switch{
        width: 2.5rem;
        height: 1.5rem;
    }
    .slider:before {
        height: 1rem;
        width: 1rem;
    }
    input:checked + .slider:before {
        left: 1.1rem;
        box-shadow: inset -3px -2px 5px -2px #8983f7, inset -7px -4px 0 0 #a3dafb;
    }
  }


  .toggleContainer{
    width:3rem;
    height:1.5rem;
    background-color: white;
    border-radius: 2rem;
    position:absolute;
  }

  .toggleBtn{
    width:1rem;
    height:1rem;
    background: linear-gradient(40deg,#ff0000,#f38601 70%);
    border-radius:50%;
    border:none;
    position:absolute;
    top:4px;
    left:4px;    
  }


  .toggleBtnDark{
    width:1rem;
    height:1rem;
    background-color: #303136;
    border-radius:50%;
    position:absolute;
    top:4px;
    right:4px;  
    border:none;
  }

  .toggleContainerDark{
    width:3rem;
    height:1.5rem;
    background-color: blue;
    border-radius: 2rem;
    position:absolute;
  }