label{
    color:aliceblue;
    margin-bottom: 0.5rem;
}
.form{
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.form h1 {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}
form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 4rem 1rem;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 0.4em;
    margin: auto;
    max-width: 600px;
    background-color: #171717;
    border-radius: 25px;
    transition: .4s ease-in-out;
  }
  
form:hover {
    transform: scale(1.05);
    border: 1px solid black;
}
form input, form textarea{
    margin-bottom: 1rem;
    padding: 10px 18px;
    font-size: 16px;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
    color: #f4f4f4;
}
form .a {
    position: relative;
    display: inline-block;
    
    padding: 10px 20px;
    text-align: center;
    max-width: 150px;
    font-weight: bold;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    margin-bottom: 30px;
    letter-spacing: 3px
}
  
.a:hover {
    background: #f3f3f7;
    color: #272727;
    border-radius: 5px;
}
  
.a span {
    position: absolute;
    display: block;
}
  
.a span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #fff);
    animation: btn-anim1 1.5s linear infinite;
}
  
@keyframes btn-anim1 {
    0% {
      left: -100%;
    }
  
    50%,100% {
      left: 100%;
    }
  }
  
.a span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #fff);
    animation: btn-anim2 1.5s linear infinite;
    animation-delay: .375s
}
  
@keyframes btn-anim2 {
    0% {
      top: -100%;
    }
  
    50%,100% {
      top: 100%;
    }
}
  
.a span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #fff);
    animation: btn-anim3 1.5s linear infinite;
    animation-delay: .75s
}
  
@keyframes btn-anim3 {
    0% {
      right: -100%;
    }
  
    50%,100% {
      right: 100%;
    }
}
  
.a span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #fff);
    animation: btn-anim4 1.5s linear infinite;
    animation-delay: 1.125s
}
  
@keyframes btn-anim4 {
    0% {
      bottom: -100%;
    }
  
    50%,100% {
      bottom: 100%;
    }
}
@media screen and (max-width: 840px){
    form {
        padding: 4rem 1rem;
        padding-left: 2em;
        padding-right: 2em;
        padding-bottom: 0.4em;
        max-width: 400px;
        
      }
}
  
