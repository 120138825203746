.main{
    display: flex;
    justify-content: center;
    align-items: center;
}

.main h1{
    display: flex;
    justify-content: center;
    margin-bottom: 2.5rem;
}
.container{
    position: relative;
    max-width: 600px;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    margin: 0 15px;
    padding: 10px 20px;
    border-radius: 7px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.title-text {
    text-align: center;
    background-color: rgb(126, 8, 137);
    color: #000000;
    font-weight: 400;
    border-radius: 50px;
    padding-top: 2px;
    padding-bottom: 5px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.container .skill-box {
    width: 100%;
    margin: 25px 0;
}

.skill-box .title{
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: #f4f4f4;
}

.skill-box .skill-bar{
    height: 8px;
    width: 100%;
    border-radius: 6px;
    margin-top: 6px;
    background: rgba(255, 255, 255, 0.1);
}

.skill-box .skill-per{
    position: relative;
    display: block;
    height: 100%;
    width: 95%;
    border-radius: 6px;
    background: rgb(220,20,60);
    background: linear-gradient(45deg, rgba(115,0,255,1) 0%, rgba(220,20,60,1) 100%);
    animation: progress 0.4s ease-in-out forwards;
    opacity: 0;
}

.skill-per.css {
    width: 85%;
    animation-delay: 0.1s;
}

.skill-per.javascript{
    width: 60%;
    animation-delay: 0.2s;
}
.skill-per.nodejs{
    width: 40%;
    animation-delay: 0.3s;
}
.skill-per.reactjs{
    width: 70%;
    animation-delay: 0.3s;
}
.skill-per.expressjs{
    width: 75%;
    animation-delay: 0.3s;
}
.skill-per.python{
    width: 90%;
    animation-delay: 0.3s;
}
.skill-per.cpp{
    width: 80%;
    animation-delay: 0.3s;
}
.skill-per.mysql{
    width: 90%;
    animation-delay: 0.3s;
}

@keyframes progress {
    0% {
        width: 0;
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.skill-per .tooltip{
    position: absolute;
    right: -14px;
    top: -28px;
    font-size: 9px;
    font-weight: 500;
    color: #fff;
    padding: 2px 6px;
    border-radius: 3px;
    background: crimson;
    z-index: 1;
}

.tooltip::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -2px;
    height: 10px;
    width: 10px;
    z-index: -1;
    background-color: crimson;
    transform: translateX(-50%) rotate(45deg);
}
@media screen and (max-width: 840px){
    .container{
        max-width: 400px;
    }
}

