.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 10;
}
.title:hover{
    box-shadow: 0 0 2px rgb(2, 6, 255); 
}
.header-bg{
    background: rgb(209,54,255);
    background: linear-gradient(45deg, rgba(209,54,255,1) 5%, rgba(105,1,140,1) 25%, rgba(47,1,90,1) 41%, rgba(38,5,115,1) 57%, rgba(2,21,223,1) 70%, rgba(0,215,255,1) 95%);
    transition: 0.5s;
}
.header h1 {
    margin: 3px;
    font-size: 2.5rem;
    height: auto;
    background: transparent;
    padding: 0;
    border: none;
}

.nav-left{
    display: flex;
    gap: 1rem;
}
  
.nav-menu{
    display: flex
}

.nav-menu li {
    padding: 0 1rem;
}
.nav-menu li a{
    font-size: 1.5rem ;
    font-weight: 500;
}
.hamburger{
    display: none;
}


@media screen and (max-width: 1040px) {
    .nav-menu {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background: rgba(36, 0, 56, 0.9);
        position: absolute;
        top: 0;
        left: -100%;
        z-index: -3 ;
        transition: 0.3s;
    }
    .nav-menu.active {
        left: 0;
    }
    .nav-menu li{
        padding: 1rem 0;
    }
    .nav-menu li a {
        font-size: 2rem;
    }
    .header h1 {
        font-size: 2rem;
    }
    .hamburger{
        display: initial;
    }
}
