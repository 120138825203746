@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Outfit', sans-serif;
}
body{
  background: black;
}
h2, h4 ,
a{
  color: rgb(255, 254, 254);
  text-decoration: none;
}
h1,p{
  color: rgb(255, 255, 255);
  text-decoration: none;
}

u1{
  list-style-type: none;
}

#button1 {
  padding: 0.8em 1.8em;
  border: 2px solid #17C3B2;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  transition: .3s;
  z-index: 1;
  font-family: inherit;
  color: #17C3B2;
}
 
#button1::before {
  content: '';
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #17C3B2;
  transition: .5s ease;
  display: block;
  z-index: -1;
}
 
#button1:hover::before {
  width: 105%;
}
 
#button1:hover {
  color: #111;
}
#button2 {
  padding: 0.8em 1.8em;
  border: 2px solid #17C3B2;
  position: relative;
  overflow: hidden;
  background-color: #17C3B2;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  transition: .3s;
  z-index: 1;
  font-family: inherit;
  color: #000;
}

#button2::before {
  content: '';
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: transparent;
  transition: .5s ease;
  display: block;
  z-index: -1;
}

#button2:hover::before {
  width: 105%;
}

#button2:hover {
  background-color: transparent;
  color: #17C3B2;
}